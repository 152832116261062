import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentChips.figmaUrl.android} codeUrl={checklists.componentChips.codeUrl.android} checklists={checklists.componentChips.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Chips allow users to enter information, make selections, filter content, or trigger actions.`}</p>
    <p>{`While buttons are expected to appear consistently and with familiar calls to action, chips should appear dynamically as a group of multiple interactive elements.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/chips_android_v2.gif",
            "width": 250,
            "height": 250,
            "align": "center",
            "resize-mode": "contain",
            "alt": "chips usage"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <p>{`Legion has two theme variants of chips :`}</p>
    <ol>
      <li parentName="ol">{`Primary`}</li>
      <li parentName="ol">{`Secondary`}</li>
    </ol>
    <p>{`and also have two size variant of chips :`}</p>
    <ol>
      <li parentName="ol">{`Large`}</li>
      <li parentName="ol">{`Small`}</li>
    </ol>
    <h2>{`Usage`}</h2>
    <h3>{`Primary`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"layout_sample.xml\"",
        "title": "\"layout_sample.xml\""
      }}>{`<com.telkom.legion.component.chips.large.LgnPrimaryLargeChipGroup
    // define your attribute's here
    ... />

`}</code></pre>
    <h4>{`programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryLargeChipGroup(requiredContext()).apply {
    //Your View's customization here
}

...
`}</code></pre>
    <h3>{`Secondary`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"layout_sample.xml\"",
        "title": "\"layout_sample.xml\""
      }}>{`<com.telkom.legion.component.chips.large.LgnSecondaryLargeChipGroup
    // define your attribute's here
    ... />
`}</code></pre>
    <h4>{`programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSecondaryLargeChipGroup(requiredContext()).apply {
    //Your View's customization here
}
...
`}</code></pre>
    <h3>{`Large`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"layout_sample.xml\"",
        "title": "\"layout_sample.xml\""
      }}>{`<com.telkom.legion.component.chips.large.LgnPrimaryLargeChipGroup
    // define your attribute's here
    ... />

`}</code></pre>
    <h4>{`programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPrimaryLargeChipGroup(requiredContext()).apply {
    //Your View's customization here
}

...
`}</code></pre>
    <h3>{`Small`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"layout_sample.xml\"",
        "title": "\"layout_sample.xml\""
      }}>{`<com.telkom.legion.component.chips.large.LgnSecondarySmallChipGroup
    // define your attribute's here
    ... />


`}</code></pre>
    <h4>{`programmatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSecondarySmallChipGroup(requiredContext()).apply {
    //Your View's customization here
}

...
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To get or set selection value on Filter Type directly`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:hint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set label value directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enable Status `}<strong parentName="td">{`[WIP]`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set enable or disable chips directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Required Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isRequired`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isRequired`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set required status on text area directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isOptional`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isOptional`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set optional status on text area directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Helper text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:helperText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helper`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set helper text directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chip Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:chipType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`chipType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set chip type directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Single Line`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isSingleLine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isSingleLine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set chips single line directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Show Close Icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:closeIconVisible`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`closeIconVisible`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set show close icon on chips directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Single Selection `}<strong parentName="td">{`[BETA]`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isSingleSelection`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isSingleSelection`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set chips single selection directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Background Color `}<strong parentName="td">{`[BETA]`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:chipBackgroundColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`chipBackgroundColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set chips background color directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Stroke Color `}<strong parentName="td">{`[BETA]`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:chipStrokeColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`chipStrokeColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set chips stroke color directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Text Color `}<strong parentName="td">{`[BETA]`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:textColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`textColors`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set chips text color directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Chips`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`addAll(List<String>)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To add chips programaticaly`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Listener`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setListener { text -> }`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To add listener on chips programaticaly`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Close Listener`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnCloseIconClickListener { text -> }`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To add close listener on chips programaticaly`}</td>
        </tr>
      </tbody>
    </table>
    <InlineNotification kind="info" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{` BETA :`}</strong>{` Attribute mean there is a possibility that it does not work as expected and there is a possibility of bugs`}</p>
    </InlineNotification>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      